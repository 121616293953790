// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
    production: false,
    logo: 'assets/favtegik.png',
    bucket_files: 'contalink-files',
    PIPELINK_API_TOKEN: '0265cdd4911c8d00da7b508d60ec5171fa4b5e00',
    PIPELINK_API_URL: 'https://contalink.pipedrive.com/api/v1',
    META_API_URL: 'https://graph.facebook.com/v14.0',
    META_PIXEL_ID: '553320291959158',
    META_API_TOKEN: 'EAATIdAbJnq0BOZCVokZAOtrXwSCbqceofQYuZCsJ9xHPprjrLMIZBqag4GI34P6LrL82MqZC7pqMTPcZAolsj4EsJKcAYN3WWFdZCktnHoUyPEMHyxR4cnThCqbVJFE0vuQcMTQq1I1r4oLlet2hz6nVJW0HHlYIgBsZAcDCYcpgLezPN2a29CgwaFryPkdZBVhrRfAZDZD',
    api_token_backend: 'J5rfNHyuqg2r8L4OcdzGV5vESnhoWdGQ80jiAI5G',

    dev: {
        api_endpoint: '/dev',
        bucket_upload: 'cl-dev-fileprocessing',
        rails_service: '/rails_dev',
        socket: 'wss://i2haoju2y4.execute-api.us-east-1.amazonaws.com/qa'

    },
    prod: {
        api_endpoint: '/prod',
        bucket_upload: 'cl-prod-fileprocessing',
        rails_service: '/rails_prod',
        socket: 'wss://i2haoju2y4.execute-api.us-east-1.amazonaws.com/production',
        url_backend: 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/prod/cl-freemium-signup-backend'

    },
    qa: {
        api_endpoint: '/qa',
        bucket_upload: 'cl-dev-fileprocessing',
        rails_service: '/rails_qa',
        socket: 'wss://i2haoju2y4.execute-api.us-east-1.amazonaws.com/qa',
        url_backend: 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/qa/cl-freemium-signup-backend',
    },
    staging: {
        api_endpoint: '/staging',
        bucket_upload: 'cl-dev-fileprocessing',
        rails_service: '/rails_staging',
        socket: 'wss://i2haoju2y4.execute-api.us-east-1.amazonaws.com/staging',


    },
    api_reports: '/api_reports'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
