import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckCompanyService {

  private url = null;
  private url_prod: string = 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/prod/cl-freemium-signup-backend/validatecompany';
  private url_qa: string = 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/qa/cl-freemium-signup-backend/validatecompany';
  private api_token: string = 'J5rfNHyuqg2r8L4OcdzGV5vESnhoWdGQ80jiAI5G';

  constructor(private http: HttpClient) {

  }

  public checkContalinkCompany(checkCompany: CheckCompany): Observable<any> {
    console.log("PARAMS")
    console.log(checkCompany)
    let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', this.api_token);
    let params = new HttpParams().set("rfc", checkCompany.rfc).set("email", checkCompany.email).set("phone", checkCompany.phone);
    if (window.location.href.includes("//signup.contalink.com")) {
      this.url = this.url_prod;
    } else {
      this.url = this.url_qa;
    }
    
    return this.http.get(this.url, {
      headers: headers,
      params: params
    });
  }
}
export interface CheckCompany {
  rfc:string,
  email:string,
  phone: string
}
