import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDividerModule} from '@angular/material/divider';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSliderModule} from '@angular/material/slider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './pages/contact/contact.component';
import { UsersComponent } from './pages/users/users.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { RetryComponent } from './pages/retry/retry.component';
import { CheckoutPaymentComponent } from './pages/checkout_payment/checkout_payment.component';
import { ConfirmPhoneComponent } from './pages/confirm_phone/confirm_phone.component';
import { PaymentComponent } from './pages/payment/payment.component';

import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {SendSignupDataService } from './services/send-signup-data.service';

import { environment } from '../environments/environment';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { MainComponent } from './main/main.component';

import { PixelModule } from 'ngx-pixel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { Data } from "./providers/data/data";
import '@stripe/stripe-js';

import { Item } from './core/model';
import * as GridTabReducers from './core/grids/grid-tab-db-table/grid-tab-db-table.reducer'
import * as CatalogsReducers from './core/store/catalogs/catalogs.reducer'
import * as NotificationsReducers from './core/store/notifications/notifications.reducer';
import * as SpinnerReducer from './core/store/spinner/spinner.reducer';
import { StateModalWebComponent, modalswbReducer } from './core/store/modal_wc';
import { StateTabWebComponent, tabswbReducer } from './core/store/tab_wc';
import { StateDashboardWebComponent, dashboardwbReducer } from './core/store/dashboard_wc';
import { StateProcess, processReducer, ProcessEffects } from './core/store/process';
import * as fromRouter from '@ngrx/router-store';
import { StateCurrDefWindow, CurrentDefWindowEffects } from './core/store/current_def_window'
import { StateFormHeader } from './core/store/form_header/form_header'
import { StateKeyDown } from './core/store/keydown/keydown'
import { StateGeneralGrid } from './core/store/general-grid/general-grid'
import { MenuState } from './core/store/menus/menus';
import { StateAdminComponents } from './core/store/admin-components/admin-components'
import { StateTextFilter } from './core/store/text-filter/text-filter'
import * as dynamicCell from './core/store/dynamic-cell/dynamic-cell';
import * as Totals from './core/store/total_grid_general/total_grid_general';
import * as LineForm from './core/store/line_form';
import { RouterStateUrl, CustomSerializer } from './core/store/router/router';


import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { ModalRfcComponent } from './components/modal-rfc/modal-rfc.component';

export const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};


@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    UsersComponent,
    CheckoutComponent,
    CheckoutPaymentComponent,
    RetryComponent,
    ConfirmPhoneComponent,
    MainComponent, 
    PaymentComponent, ModalRfcComponent
  ],
  imports: [
    BrowserModule,
    PixelModule.forRoot({ enabled: true, pixelId: '553320291959158' }),
    AppRoutingModule,
    MatCardModule,
    MatGridListModule,
    MatDividerModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatSliderModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    HttpClientModule,
        NotifierModule.withConfig(customNotifierOptions),
    ReactiveFormsModule,
        BrowserAnimationsModule,
        MatIconModule
  ],
  providers: [Data,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


//Inicializando el store
export interface AppStore {
    items: Item[];
    position: string;
    refresh: boolean;
    lines_tabs: GridTabReducers.State;
    buttons_tab: GridTabReducers.StateTabButton;
    catalogs: CatalogsReducers.StateCatalogs;
    notification: NotificationsReducers.StateNotification;
    spinner: SpinnerReducer.StateSpinner;
    modals_wc: StateModalWebComponent;
    tabs_wc: StateTabWebComponent;
    dashboards_wc: StateDashboardWebComponent;
    process: StateProcess;
    router: fromRouter.RouterReducerState<RouterStateUrl>,
    curr_def_window: StateCurrDefWindow,
    form_header: StateFormHeader,
    keydown: StateKeyDown,
    general_grid: StateGeneralGrid,
    user: any,
    menus: MenuState,
    adminComponent: StateAdminComponents,
    text_filter: StateTextFilter,
    dynamic_cell: dynamicCell.StateDynamicCellComponent,
    totals: Totals.StateTotalGridGeneral,
    line_form: LineForm.StateLineForm
}
